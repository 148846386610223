<template>
  <base-mobile-list class="employees-mobile-list" :items="items">
    <template v-slot:item="{ item }">
      <div class="mt-6">
        <v-btn
          class="employees-mobile-list__full-name text-body-1 font-weight-medium px-0 text-none"
          text
          @click="showDetailInfo(item)"
        >
          {{ item.fullName }}
        </v-btn>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="6" class="pr-1">
            <view-list-field :label="$t('employees.email')">
              {{ item.email }}
            </view-list-field>
          </v-col>
          <v-col cols="6" class="pl-1">
            <view-list-field :label="$t('employees.phone')" align="right">
              {{ item.phone }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="mt-6">
        <v-row no-gutters>
          <v-col cols="12" class="pr-1">
            <view-list-field :label="$t('employees.credentials')">
              {{ joinWithLimit(translateKeys(item.credentials)) }}
            </view-list-field>
          </v-col>
        </v-row>
      </div>

      <div class="d-flex flex-row mt-6">
        <view-list-field class="flex-grow-1" :label="$t('employees.projectsName')">
          {{ joinWithLimit(translateKeys(item.projectsName)) }}
        </view-list-field>
        <div class="d-flex align-end text-caption text--secondary">
          <div class="d-flex align-center">
            {{ $t('label.select') }}

            <base-checkbox
              :input-value="getSelectedStatus(item)"
              class="employees-mobile-list__checkbox ml-1 pt-0 mt-0"
              hide-details
              @change="select(item)"
            />
          </div>
        </div>
      </div>
    </template>
  </base-mobile-list>
</template>

<script>
// Components
import BaseMobileList from '@/components/BaseMobileList.vue';
import ViewListField from '@/components/ViewListField.vue';
import BaseCheckbox from '@/components/BaseCheckbox.vue';

// utils
import { createModelWrapper } from '@/utils/components';
import joinWithLimit from '@/utils/joinWithLimit';

export default {
  name: 'EmployeesMobileList',

  components: { BaseMobileList, ViewListField, BaseCheckbox },

  model: {
    prop: 'selectedItems',
    event: 'update:selectedItems',
  },

  props: {
    items: { type: Array, required: true },
    selectedItems: { type: Array, default: () => [] },
  },

  data() {
    return {
      isSelected: false,
    };
  },

  computed: {
    selectedItemsModel: createModelWrapper('selectedItems', 'update:selectedItems'),
  },

  methods: {
    getSelectedStatus(item) {
      return this.selectedItemsModel.findIndex(usage => usage.id === item.id) !== -1;
    },

    select(item) {
      if (this.getSelectedStatus(item)) {
        const filteredValue = this.selectedItemsModel.filter(usage => usage.id !== item.id);

        this.selectedItemsModel = filteredValue;
        return;
      }

      this.selectedItemsModel = [...this.selectedItems, item];
    },

    translateKeys(keys) {
      return keys?.length ? keys.map(key => this.$t(key)) : keys;
    },

    joinWithLimit,

    showDetailInfo(item) {
      this.$emit('open', item);
    },
  },
};
</script>

<style lang="scss">
.employees-mobile-list {
  &__full-name {
    color: $--primary-color !important;
  }

  &__checkbox {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
  }
}
</style>
