<template>
  <v-data-table class="data-table" :options.sync="localOptions" v-bind="$attrs" v-on="inheritedListeners">
    <template v-for="slot in getItemSlots()" v-slot:[slot]="slotProps">
      <slot :name="slot" v-bind="slotProps" />
    </template>

    <template #no-data>
      {{ $t('list.missing_data') }}
    </template>
    <template #loading>
      {{ $t('list.loading') }}
    </template>
  </v-data-table>
</template>

<script>
// Utils
import { getInheritedListeners } from '@/utils/components';
import { isEqual } from '@/utils/common';

export default {
  name: 'BaseTable',

  inheritAttrs: false,

  props: {
    options: { type: Object, default: () => ({}) },
  },

  data() {
    return {
      localOptions: {},
    };
  },

  computed: {
    inheritedListeners: getInheritedListeners(['update:options']),
  },

  watch: {
    localOptions: {
      handler(newOptions) {
        const { sortBy, sortDesc } = newOptions;

        const originOptions = { sortBy: sortBy[0], sortDesc: sortDesc[0] };

        if (!isEqual(originOptions, this.options)) {
          this.$emit('update:options', originOptions);
        }
      },

      deep: false,
    },

    options: {
      handler(newOptions) {
        this.localOptions = { ...this.options, sortBy: [newOptions.sortBy], sortDesc: [newOptions.sortDesc] };
      },

      immediate: true,
      deep: true,
    },
  },

  methods: {
    getItemSlots() {
      return Object.keys(this.$scopedSlots);
    },
  },
};
</script>

<style lang="scss">
.data-table {
  // @test
  // .v-data-table__wrapper {
  //   overflow: auto;
  //   position: relative;

  //   & > table {
  //     display: inline-block;
  //     vertical-align: top;
  //     max-width: 100%;
  //     overflow-x: auto;
  //     padding-bottom: 5px;

  //     th {
  //       white-space: wrap;
  //     }
  //     td {
  //       white-space: nowrap;
  //     }

  //     &::-webkit-scrollbar {
  //       height: 6px;
  //     }

  //     &::-webkit-scrollbar-track {
  //       background: transparent;
  //     }

  //     &::-webkit-scrollbar-thumb {
  //       background-color: $--blue-color-20;
  //       border-radius: 20px;
  //       border: none;
  //     }
  //   }
  // }

  .v-data-table__wrapper > table > thead > tr > .text-start > .v-data-table-header__icon {
    margin-left: 8px;
  }

  .v-data-table__wrapper > table > thead > tr > .text-start,
  &__sort-button {
    color: rgba($--blue-color-30, 0.5) !important;
    vertical-align: top;
  }

  .v-data-table__wrapper > table > thead > tr > .text-start.active,
  .v-data-table__wrapper > table > thead > tr > .text-start.active > .v-data-table-header__icon {
    color: $--blue-color-0 !important;
  }

  .v-data-table__wrapper > table > tbody > tr > td:not(.v-data-table__mobile-row),
  .v-data-table__wrapper > table > thead > tr:last-child > th {
    border-bottom: thin solid rgba($--blue-color-20, 0.1) !important;
    @media (max-width: map.get($--screens, 'md')) {
      padding: 0 8px;
    }
  }

  .mdi-checkbox-blank-outline::before {
    color: rgba($--blue-color-20, 0.5);
  }

  .mdi-minus-box::before {
    color: $--blue-color-20;
  }

  .v-data-table__selected:not(:hover) {
    background: none !important;
  }

  .v-data-table__checkbox {
    height: 100%;
    display: flex;
    align-items: center;
  }
}
</style>
