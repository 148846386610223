<template>
  <v-bottom-sheet v-model="isOpen" class="mobile-search-menu mb-auto pb-2" fullscreen>
    <template v-slot:activator="{ on, attrs }">
      <text-field
        readonly
        hide-details
        prepend-inner-icon="mdi-magnify"
        v-bind="attrs"
        :value="inputValue"
        :placeholder="$t('button.search')"
        v-on="on"
      >
      </text-field>
    </template>

    <v-form class="mobile-search-menu__overlay" :style="{ height: overlayHeight }" @submit.prevent="search">
      <v-sheet>
        <v-list
          class="mobile-search-menu__list d-flex flex-column justify-space-between mt-4 pa-0"
          dense
          :style="{ height: listHeight }"
        >
          <div>
            <v-list-item class="d-flex flex-grow-0 pa-2">
              <label class="d-flex flex-grow-1">
                <v-icon>mdi-magnify</v-icon>
                <input
                  v-model="inputValue"
                  class="mobile-search-menu__input text-body-1 ml-1"
                  :placeholder="placeholder"
                />
              </label>

              <v-btn plain icon x-small @click="closeMenu">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-list-item>

            <v-divider />
          </div>

          <v-list-item class="pa-2">
            <v-btn class="mobile-search-menu__search-button d-flex mt-auto" block @click="search">
              {{ $t('button.search') }}
            </v-btn>
          </v-list-item>
        </v-list>
      </v-sheet>
    </v-form>
  </v-bottom-sheet>
</template>

<script>
// Utils
import { throttle } from '@/utils/delay';

// Components
import TextField from '@/components/TextField.vue';

export default {
  name: 'MobileSearchMenu',

  components: { TextField },

  model: {
    prop: 'value',
    event: 'update:value',
  },

  props: {
    placeholder: { type: String, default: '' },
    value: { type: String, required: true },
    hideDetails: { type: Boolean, default: false },
  },

  data: () => ({
    isOpen: false,
    inputValue: '',
    windowHeight: window.innerHeight,
  }),

  computed: {
    overlayHeight() {
      return `${this.windowHeight}px`;
    },

    listHeight() {
      return `${this.windowHeight - 16}px`;
    },
  },

  watch: {
    isOpen() {
      if (this.isOpen) this.inputValue = this.value;
    },

    value: {
      handler(query) {
        this.updateQuery({ q: query || '' });
      },
      immediate: true,
    },
  },

  mounted() {
    this.$options.throttledResize = throttle(() => {
      this.onResize();
    }, 1000);

    this.$nextTick(() => {
      window.addEventListener('resize', this.$options.throttledResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.$options.throttledResize);
  },

  methods: {
    closeMenu() {
      this.isOpen = false;
    },

    search() {
      this.$emit('update:value', this.inputValue);
      this.closeMenu();
    },

    updateQuery(query) {
      const currentQuery = this.$route.query;

      this.$router.replace({ query: { ...currentQuery, ...query } });
    },

    onResize() {
      this.windowHeight = window.innerHeight;
    },
  },
};
</script>

<style lang="scss">
.mobile-search-menu {
  &__overlay {
    position: absolute;
    background: rgba($--black-color-0, 0.8);
  }

  &__input {
    outline: none;
    width: 100%;
  }

  &__search-button {
    color: $--mobile-sort-menu-secondary-font-color !important;
    background: $--mobile-sort-menu-background-selected !important;
  }
}
</style>
