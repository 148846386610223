<template>
  <div class="view-list-field" :class="{ 'view-list-field--inline': inline }">
    <div class="text-caption text--secondary flex-shrink-0" :class="[`text-${align}`, { 'mr-1': inline }]">
      <slot name="label">{{ label }}</slot>
    </div>
    <div class="text-body-2 font-weight-medium" :class="`text-${align}`"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'ViewListField',

  props: {
    label: { type: String, default: '' },
    align: { type: String, default: 'left' },
    inline: { type: Boolean, default: false },
  },
};
</script>

<style lang="scss">
.view-list-field {
  word-break: break-all;

  &--inline {
    display: flex;
  }
}
</style>
